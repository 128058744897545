<template>
  <div class="worship-visit-eulogy" v-infinite-scroll="getEulogies">
    <div class="eulogy-add"><button type="button">增加</button></div>
    <div class="eulogy-list">
      <div class="eulogy-item" v-for="(item, index) in lists" :key="item.id">
        <div class="item-top">
          <div class="top-title">
            <strong>{{ item.title }}</strong>
            <span v-if="item.author.name">作者：{{ item.author.name }}</span><span>{{item.deliver_time}}发布</span>
          </div>
          <div class="top-visit">
            <span class="visit-span">浏览（{{item.visit_num}}）</span>
            <span class="visit-span">评论（{{item.comment_num}}）</span>
          </div>
        </div>
        <div class="item-content mui-ellipsis-3">
         {{ item.content }}
        </div>
        <div class="item-bot">
          <div class="bot-lef">
            <span><i class="sea-iconfont">&#xe6af;</i>撰写</span>
            <span><i class="sea-iconfont">&#xe602;</i>分享</span>
            <span><i class="sea-iconfont">&#xe656;</i>删除</span>
          </div>
          <div class="bot-rig">
            阅读全文<i class="sea-iconfont">&#xe61e;</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import util from '@/utils/util'
  export default {
    name: 'worship-visit-eulogy',
    data() {
      return {
        hallId: '48e32dc432204326a86f8ce00285dab2',
        lists: [],
        paging: {
          page: 1,
          pageSize: 10,
          last: false,
        }
      }
    },
    methods: {
      getEulogies: function () {
        let _this = this;
        if(_this.paging.last){
          return;
        }
        let param = {
          hallId: _this.hallId
        };
        _this.$api.hall.getEulogies(param)
          .then(res => {
            if (res.data) {
              res.data.data.forEach((data, i) => {
                data.deliver_time = util.formatDate(data.deliver_time, 'yyyy.MM.dd');
                if (!data.visit_num) {
                  data.visit_num = 0;
                }
                if (!data.comment_num) {
                  data.comment_num = 0;
                }
                _this.lists.push(data);
                _this.paging.last = true
              });
              // _this.paging.last = (_this.paging.page >= res.data.paging.pages);
              // if (!res.data.paging.last){
              //   _this.paging.page++
              // }
            }
          });
      },
    }
  }
</script>

<style lang="scss" scoped>
.worship-visit-eulogy {
  position: relative;
  z-index: 22;
  height: 927px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .eulogy-add {
    top: 20px;
    right: 30px;
    z-index: 33;
    position: absolute;
    button {
      width: 150px;
      height: 50px;
      background: linear-gradient(-90deg, #7AAADA 0%, #407EB2 100%);
      box-shadow: -2px 3px 5px 1px rgba(101, 101, 100, 0.5);
      border-radius: 25px;
      font-size: 18px;
      border: none;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .eulogy-list {
    margin-top: 90px;
    .eulogy-item {
      background: #FFFFFF;
      border: 1px solid #407EB2;
      border-radius: 10px;
      margin-bottom: 21px;
      padding: 30px 11px 20px 11px;
      .item-top {
        @include w-flex;
        justify-content: space-between;
        .top-title {
          strong {
            font-size: 20px;
            font-weight: bold;
            color: #2F2F2F;
            line-height: 26px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #444444;
            line-height: 26px;
            margin-left: 59px;
          }
        }
        .visit-span {
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 26px;
          margin-left: 20px;
        }
      }
      .item-content {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 26px;
        text-indent: 24px;
        margin: 20px 0;
      }
      .item-bot {
        @include w-flex;
        justify-content: space-between;
        border-top: 1px dotted  #407EB2;
        padding-top: 20px;
        .bot-lef {
          @include w-flex;
          span {
            @include w-flex;
            font-size: 16px;
            font-weight: 400;
            color: #444444;
            line-height: 26px;
            margin-right: 28px;
            .sea-iconfont {
              font-size: 24px;
            }
          }
        }
        .bot-rig {
          font-size: 14px;
          font-weight: 400;
          color: #444444;
          line-height: 26px;
          .sea-iconfont {
            margin-left: 6px;
          }
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
   }
}
</style>
